import React, { useState } from "react";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import './NewHousebanDialog.css';

export function NewHousebanDialog(props) {

	const { onClose, open } = props;
	const [ name, setName ] = useState("");
	const [ until, setUntil ] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]);
	const [ companyWide, setCompanyWide ] = useState(false);
	const [ corporationWide, setCorporationWide ] = useState(false);
	const [ images, setImages ] = useState([]);
	const [ acutalImage, setActualImage ] = useState(0);
	
	function handleSave(){
		
		let person = {
			id: 0,
			store: 0,
			until: until,
			name: name,
			companyWide: companyWide,
			corporationWide: corporationWide,
			images: images
		}
		
		onClose(person);
		
	}
	
	function handleCancel(){
		onClose(null);
	}
	
	function imageuploaded(event){
		
		const toastid = toast.loading('Loading picture ...');
		
		let file = event.target.files[0];
		
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            handleUploadedPicture(toastid, reader.result);
        };
        reader.onerror = function (error) {
            
            console.log('Error: ', error);
            
            toast.update(toastid, { render: error, type: "error", isLoading: false, autoClose: 5000 });
            
        };
		
	}
	
	function handleUploadedPicture(toastid, image){
		
       	setImages([...images, image]);
       	
       	console.log(images);
        	
       	toast.update(toastid, { render: "Picture loaded", type: "success", isLoading: false, autoClose: 5000 })
		
	}
	
	function decreaseImageNumber(){
		
		let tmpImageNumber = acutalImage - 1;
		
		if(tmpImageNumber >= 0){
			setActualImage(tmpImageNumber);
		}
		
	}
	
	function increaseImageNumber(){
		
		let tmpImageNumber = acutalImage + 1;
		
		if(tmpImageNumber < images.length){
			setActualImage(tmpImageNumber);
		}
		
	}

	return (
		<Dialog onClose={handleCancel} open={open} fullWidth maxWidth="lg">
			<DialogTitle>Add new house banned person</DialogTitle>
			<DialogContent>
				<div className="dialogcontentarea">
					<div className="dialogcontenthalfarea dialogcontentleftarea">
						{ images.length > 0 &&
							<img alt="banned person" src={images[acutalImage]} />
						}
						{images.length > 1 &&
							<span onClick={decreaseImageNumber}>
								<FontAwesomeIcon icon="square-chevron-left" title="prev" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</span>
						}
						<input type="file" name="myImage" onChange={imageuploaded} />
						{images.length > 1 &&
							<span onClick={increaseImageNumber}>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="square-chevron-right" title="next" />
							</span>
						}
					</div>
					<div className="dialogcontenthalfarea dialogcontentrigtharea">
						<input placeholder="name" value={name} onChange={e => setName(e.target.value)} /><br/>
						Until: <input type="date" value={until} onChange={e => setUntil(e.target.value)} /><br />
						company wide: <input type="checkbox" onChange={e => setCompanyWide(e.target.checked)} /><br />
						corporation wide: <input type="checkbox" onChange={e => setCorporationWide(e.target.checked)} />
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel}>Cancle</Button>
				<Button onClick={handleSave}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

NewHousebanDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};