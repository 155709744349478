import React/*, {useEffect, useCallback, useMemo}*/ from 'react';
/*import Cookies from 'universal-cookie';*/
import { /*useDispatch, */useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import './env-config';

import { Login } from './pages/Login';
import { StoreList } from './pages/StoreList';
import { Store } from './pages/Store';
import { Housebanlist } from './pages/Housebanlist';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export function App() {
	
	const loggedIn = useSelector(state => state.usermangement.loggedIn);
	library.add(faChevronLeft);
	
	return (
		<Router>
			{ loggedIn &&
				<>
					<Routes>
						<Route path='/' element={<StoreList />} />
						<Route path='/:id' element={<Store />} />
						<Route path='/:id/housebanlist' element={<Housebanlist />} />
					</Routes>
				</>
			}
			{!loggedIn &&
				<Login />
			}
			<ToastContainer />
		</Router>
	);
}
